import {ExtendedEnvironment} from "./environment.interface";

const clientUrl = 'https://app.projotech.com';
const apiUrl = 'https://api.app.projotech.com/';

export const environment = {
  production: true,
  defaultTheme: 'theme5',
  application: {
    clientUrl,
    name: 'Projotech',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: apiUrl,
    redirectUri: clientUrl,
    clientId: 'Projotech_App',
    responseType: 'code',
    scope: 'offline_access Projotech',
    requireHttps: true,
  },
  apis: {
    default: {
      url: apiUrl,
      rootNamespace: 'LX.Projotech',
    },
  },
  firebaseConfig: {
    apiKey: "AIzaSyA2GjwdomjwDm-4SMRX4JGsy6pXv28Iuq0",
    authDomain: "lx-prod-5514b.firebaseapp.com",
    projectId: "lx-prod-5514b",
    storageBucket: "lx-prod-5514b.appspot.com",
    messagingSenderId: "545916703709",
    appId: "1:545916703709:web:19aa9d4f519967cae3dc34",
    vapidKey: 'BCmOw9lWdlqfKLylYzDvV7j5KUkmlY5yuS8QWJFZQac2Oyvf3F1gQuuIDFtH5eS4Ky_c3Z1gVTFUt86N2poHXFs'
  }
} as ExtendedEnvironment;
